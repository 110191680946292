html * {
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 300;
  font-style: normal;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont,'Times';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgba(255, 252, 243, 1);
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
a {
    text-decoration: none;
}
ul {
    list-style-type: none;
    padding-left: 0px;
    padding-right: 0px
}
p {
  font-size: 16px;
}
.bgColorGreen {
  background-color:rgba(50, 59, 43, 1);
}
.bgColorTan {
  background-color: rgba(208, 203, 189, 1);
}
.fontColorGreen {
  color:rgba(50, 59, 43, 1);
}
.fontColorCream{
  color:rgba(255, 252, 243, 1);
}
.headingTextDecor {
  text-decoration: overline;
  text-decoration-thickness: 2px;
}
.sectionHeader {
  text-align: center;
  font-size: 2.2rem;
}
.sectionSubHeader {
  font-size: 1.5rem;
}
.bubbleHover:hover {
  background-color: black;
}

/* Desktop Navbar */
  .desktopNavWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(208, 203, 189, 1);
    padding-top: 12px; 
    padding-left: 30px;
    padding-right: 30px;
  }
  .desktopLogo {
    font-size: 30px;
    text-decoration: none !important;
  }
  .desktopLogo:hover {
    color: rgba(50, 59, 43, 1);
  }
  .desktopNavLinks {
    display: flex;
    align-items: center;
    gap:30px;
  }
.navLink {
  font-weight: 300;
}
  .navLink:hover {
    text-decoration: overline;
    text-decoration-color: rgba(50, 59, 43, 1);
    color: rgba(50, 59, 43, 1);
    font-size: 16px;
  }
 
 

/* Mobile Navbar */
.mobileNavbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.navbar-brand-brackets {
  font-family: 'Be Vietnam Pro';
}
.navbar-brand-size {
  font-size: 30px;
}
.offcanvas {
  background-color: rgba(50, 59, 43, 1);
  color: rgba(208, 203, 189, 1);
  font-size: 18px;
  text-align: center;
}
.offcanvas-title {
  font-size: 30px;
}
.nav-link {
  color: rgba(208, 203, 189, 1);
}
.nav-link:hover {
  font-weight: 600;
  color: rgba(208, 203, 189, 1);
  font-size: 20px;
}

  @media only screen and (min-width: 800px) {
    .mobileNavbar {
      display: none;
    }
  }
  @media only screen and (max-width: 799px) {
    .desktopNavbar {
      display: none;
    } 
  }

  /* All sections */
  .section {
    padding-top: 50px;
  }

  /* Home Section */
.landingContent {
  height:90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  flex-direction: row;
}
.landingHeader{
  font-size: 35px;
}
.headingQuote{
  font-size: 20px;
}

@media only screen and (min-width: 280px) and (max-width: 411px) {
  .landingContent {
    height: 100vh;
    flex-direction: column;
    gap: 0px;
    padding-top: 70px;
  }
  .profileImg {
    width: 250px;
  }
  .landingHeader {
    font-size: 25px;
  }
  .headingQuote {
    font-size: 15px;
  }
}
@media only screen and (min-width: 412px) and (max-width: 429px) {
  .landingContent {
    height: 100vh;
    flex-direction: column;
    gap: 0px;
    padding-top: 70px;
  }
  .profileImg {
    width: 300px;
  }
  .landingHeader {
    font-size: 35px;
  }
  .headingQuote {
    font-size: 20px;
  }
}
@media only screen and (max-width: 430px) {
  .landingContent {
    height: 100vh;
    flex-direction: column;
    gap: 0px;
    padding-top: 70px;
  }
  .profileImg {
    width: 300px;
  }
}
@media only screen and (min-width: 430px) and (max-width: 500px) {
  .landingContent {
    height: 100vh;
    flex-direction: column;
    gap: 0px;
    padding-top: 70px;
  }
  .profileImg {
    width: 350px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 770px) {
  .landingContent {
    height: 100vh;
    flex-direction: column;
    gap: 0px;
    padding-top: 70px;
  }
  .profileImg {
    width: 400px;
  }
}



/* About Section */
.aboutWrapper {
  display: flex; 
  flex-wrap: wrap;
}
.aboutCol {
  flex:1;
  padding: 25px 25px 25px 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.aboutColText {
  text-align: center;
}
.aboutQuote {
  font-size: 16px;
  font-weight: 500;
}
@media only screen and (max-width: 799px) {
  .aboutWrapper {
    flex-direction: column;
  }
}



/* Portfolio Section */
.portfolioWrapper {
  padding-bottom: 50px;
}
.portfolioImgSize {
  width:250px;
}
.porfolioFeaturedSites {
  padding-top:25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.portfolioToggleBtn {
  padding-top: 50px;
  display: flex;
  justify-content: center;
}
.seeMoreBtn {
  color:rgba(255, 252, 243, 1);
  background-color: rgba(50, 59, 43, 1) ;
  border-color: rgba(50, 59, 43, 1);
  border-radius: 50px;
  padding: 10px 30px 10px 30px;
}
.seeMoreBtn:hover { 
  background-color: rgba(255, 252, 243, 1);
  color: rgba(50, 59, 43, 1);
}
.portfolioSeeMoreSection {
  padding-top: 20px;
}
/*
.seeMoreContent {

}
*/
.seeProjectsBtn {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
.seeProjectsLink {
  color: rgba(255, 252, 243, 1);
  background-color: rgba(50, 59, 43, 1);
  border-radius: 50px;
  padding: 10px 40px;
}
.seeProjectsLink:hover { 
background-color: rgba(255, 252, 243, 1);
color: rgba(50, 59, 43, 1);
font-weight: 400;
border-style: solid;
border-width: 1px;
border-color: rgba(50, 59, 43, 1);;
}
.portfolioSeeMoreSites {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 50px;
}
@media only screen and (max-width: 799px) {
  .portfolioImgSize {
    width:150px;
  }
}



/* Services Section */
.servicesWrapper {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.servicesAccordion {
  padding-top: 20px;
  width:60%;
}
.accordion-button {
  padding: 10px;
}
.accordion-button {
  border-radius: 50px !important;
  background-color: rgba(255, 252, 243, 1);
  color: rgba(50, 59, 43, 1); ;
}
.accordion-button:not(.collapsed) {
  color: #FFFCF3;
  background-color: #323B2B;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #FFFCF3;
}
.accordion-button:focus  {
  box-shadow: none;
}
button::after {
  position: absolute;
  z-index: 100;
  right: 16px;
}
.accordion-item {
  margin-bottom: 10px;
  border-radius: 50px !important;
  color: rgba(50, 59, 43, 1);
  background-color: rgba(255, 252, 243, 1);
}
.accordion-body {
  text-align: center;
}
@media only screen and (max-width: 650px) {
  .servicesAccordion {
    width: 100%;
    margin:0;
  }
}


/* Contact Section */
.contactWrapper {
  padding-bottom: 50px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactForm{ 
  max-width:500px;
  margin:auto;
}
.contactInputDiv {
  padding-top: 10px;
}
.contactFormBtn {
  color:rgba(255, 252, 243, 1);
  background-color: rgba(50, 59, 43, 1);
  border-color: rgba(255, 252, 243, 1);
  border-radius: 50px;
  padding: 10px 40px;
}
.contactFormBtn:hover { 
  background-color: rgba(255, 252, 243, 1);
  color: rgba(50, 59, 43, 1);
  font-weight: 400;
}



/* Projects Page */
.projectPageWrapper {
  margin-top: 50px;
  margin-bottom: 70px;
}
.projectPageSection {
  margin-top: 50px;
  margin-bottom: 30px;
}
.projectSectionContent {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  justify-content: center;
}

@media only screen and (max-width: 799px) {
  .projectPageWrapper {
    margin-top: 100px;
  }
  .projectSectionContent {
    gap: 20px;
  }
}



/* Footer Section */
.footerWrapper {
  padding-top: 50px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.footerIconLink {
  width: 32px;
}
.copyrightLink {
  text-decoration: none !important;
  color: rgba(50, 59, 43, 1);
  font-size: 12px;
}